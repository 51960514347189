<template>
  <questionnaire-initial-page-template
    :language="locale"
    :language-options="languageOptions"
    :loading="loading"
    vertically-center-content
    @change-language="onChangeLanguage"
    @back="$emit('back')"
    @continue="onContinue"
  >
    <email-verification-form
      ref="form"
      :email="email"
      :disabled-time-left="disabledTimeLeft"
      :value="value"
      :error="error"
      @input="$emit('input', $event)"
      @send-code="$emit('send-code')"
      @submit.native.prevent="onContinue"
    />
  </questionnaire-initial-page-template>
</template>

<script>
import QuestionnaireInitialPageTemplate from '@/modules/questionnaire/new-design-components/templates/QuestionnaireInitialPageTemplate';
import EmailVerificationForm from '@/modules/questionnaire/pages/email-verification/EmailVerificationForm';

export default {
  name: 'EmailVerificationPageTemplate',
  components: { QuestionnaireInitialPageTemplate, EmailVerificationForm },
  props: {
    disabledTimeLeft: {
      /* Time left for the send button to be disabled in ms. */
      type: Number,
      default: 0
    },
    value: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    locale: {
      type: String,
      default: ''
    },
    languageOptions: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onContinue() {
      const isValid = this.$refs.form.validate();

      if (isValid) {
        this.$emit('continue');
      }
    },
    onChangeLanguage(code) {
      this.$emit('change-language', code);
    }
  }
};
</script>
