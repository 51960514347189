<template>
  <email-verification-page-template
    v-model="verificationCode"
    :error="errorMessage"
    :email="accessDetails.email"
    :locale="locale"
    :language-options="languageOptions"
    :loading="isLoading"
    :disabled-time-left="sendCodeTimeLeft"
    @change-language="onChangeLanguage"
    @back="onBackClick"
    @continue="onContinueClick"
    @send-code="onSendCode"
  />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import EmailVerificationPageTemplate from '@/modules/questionnaire/pages/email-verification/EmailVerificationPageTemplate';

import { getHashedId } from '@/utils';
import { changeLanguageMixin } from '@/modules/questionnaire/mixins/changeLanguageMixin';

import { types as questTypes } from '@/modules/questionnaire/store/types';

const SEND_CODE_TIMEOUT = 120000;

const TIMER_EXPIRATION_DATE_STORAGE_KEY = 'sendCodeExpirationDate';

export default {
  name: 'PageEmailVerification',
  components: { EmailVerificationPageTemplate },
  mixins: [changeLanguageMixin],
  data() {
    return {
      isLoading: false,
      errorMessage: '',
      verificationCode: '',
      sendCodeTimeLeft: 0,
      sendCodeTimerInterval: null
    };
  },
  computed: {
    ...mapState({
      accessDetails: state => state.questionnaire.accessDetails
    }),
    ...mapGetters({
      doctor: questTypes.getters.GET_DOCTOR,
      requestedDoctor: questTypes.getters.GET_REQUESTED_DOCTOR
    })
  },
  created() {
    this.getTimerFromSessionStorage();
  },
  beforeDestroy() {
    clearInterval(this.sendCodeTimerInterval);
  },
  methods: {
    ...mapActions({
      requestQuestionnaireAccess: questTypes.actions.REQUEST_QUESTIONNAIRE_ACCESS
    }),
    onBackClick() {
      this.$router.replace({
        name: 'GetAcquainted',
        params: { doctorHash: getHashedId(this.accessDetails.currentDoctorId) }
      });
    },
    resetErrorState() {
      this.errorMessage = '';
    },
    async onContinueClick() {
      this.resetErrorState();

      this.isLoading = true;
      const { errors } = await this.requestQuestionnaireAccess({
        ...this.accessDetails,
        verificationCode: this.verificationCode
      });
      this.isLoading = false;

      const hasRequestErrors = errors && errors.length > 0;

      if (hasRequestErrors) {
        this.errorMessage = 'transfer.label.verificationCodeInputError';
        return;
      }

      if (this.doctor.id !== this.requestedDoctor.id) {
        this.$router.replace({ name: 'ConnectWithOtherDoctor' });
        return;
      }

      await this.$router.replace({ name: 'DataIsSafeWithUs' });
    },
    async onSendCode() {
      this.resetErrorState();

      await this.requestQuestionnaireAccess({ ...this.accessDetails });
      this.startSendCodeTimer(SEND_CODE_TIMEOUT);
    },
    startSendCodeTimer(timeout) {
      this.sendCodeTimeLeft = timeout;

      this.sendCodeTimerInterval = setInterval(() => {
        this.sendCodeTimeLeft -= 1000;

        if (this.sendCodeTimeLeft > 0) {
          this.saveTimeToTimerExpireInSessionStorage();
          return;
        }

        this.sendCodeTimeLeft = 0;
        this.removeTimerExpirationDateFromSessionStorage();
        clearInterval(this.sendCodeTimerInterval);
      }, 1000);
    },
    getTimerFromSessionStorage() {
      const expirationDateInMilliseconds = sessionStorage.getItem(
        TIMER_EXPIRATION_DATE_STORAGE_KEY
      );

      if (!expirationDateInMilliseconds) {
        return;
      }

      const timeLeft = expirationDateInMilliseconds - new Date().getTime();

      if (timeLeft < 0) {
        this.removeTimerExpirationDateFromSessionStorage();
        return;
      }

      const timerTimeToLeft = Math.floor(timeLeft / 1000) * 1000;
      this.startSendCodeTimer(timerTimeToLeft);
    },
    saveTimeToTimerExpireInSessionStorage() {
      if (this.sendCodeTimeLeft <= 0) {
        return;
      }

      const expirationDateInMilliseconds = new Date().getTime() + this.sendCodeTimeLeft;
      sessionStorage.setItem(TIMER_EXPIRATION_DATE_STORAGE_KEY, expirationDateInMilliseconds);
    },
    removeTimerExpirationDateFromSessionStorage() {
      sessionStorage.removeItem(TIMER_EXPIRATION_DATE_STORAGE_KEY);
    }
  }
};
</script>
