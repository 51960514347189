<template>
  <u-form ref="form" class="email-verification-form">
    <h2 class="q-mt0 q-mb16 u-typography-helvetica u-text-display u-text-display--s">
      {{ $t('emailVerificationPage.title') }}
    </h2>

    <i18n
      path="emailVerificationPage.introText"
      tag="div"
      class="email-verification-form__text q-mb24 u-typography-helvetica u-text u-text--s"
    >
      <span class="email-verification-form__text--accent">({{ email }})</span>
    </i18n>

    <u-input
      class="q-mb24"
      :value="value"
      :label="$t('emailVerificationPage.label.code')"
      :validators="[validators.REQUIRED]"
      @input="$emit('input', $event)"
    />

    <div
      v-if="error"
      class="email-verification-form__error-message u-typography-gilroy u-text u-text--xs"
    >
      <u-icon name="alert-triangle" /> {{ $t(error) }}
    </div>

    <u-button
      class="q-mb32 q-centered"
      type="button"
      kind="text"
      :disabled="!!minutesLeft"
      @click="$emit('send-code')"
      >{{ $t('emailVerificationPage.action.sendCodeAgain') }}
      <span v-if="minutesLeft">{{ minutesLeft }}</span></u-button
    >

    <div
      v-if="minutesLeft"
      class="email-verification-form__text q-mb32 u-typography-helvetica u-text u-text--xs"
    >
      <div>{{ $t('emailVerificationPage.text.weHaveSentNewCode') }}</div>
      <div>{{ $t('emailVerificationPage.text.havingProblems') }}</div>

      <i18n path="emailVerificationPage.text.contactSupport" tag="div">
        <span class="email-verification-form__text--accent">info@universkin.com</span>
      </i18n>
    </div>
  </u-form>
</template>

<script>
import { UInput, UForm, UButton, UIcon } from 'universkin-shared';

import { validatable } from '@/mixins/validatable';

const ONE_MINUTE = 1000 * 60;

export default {
  name: 'EmailVerificationForm',
  components: { UInput, UForm, UButton, UIcon },
  mixins: [validatable],
  props: {
    email: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    disabledTimeLeft: {
      type: Number,
      default: 0
    },
    error: {
      type: String,
      default: ''
    }
  },
  computed: {
    minutesLeft() {
      if (this.disabledTimeLeft === 0) {
        return '';
      }

      const minutes = Math.floor(this.disabledTimeLeft / ONE_MINUTE);
      const seconds = (this.disabledTimeLeft % ONE_MINUTE) / 1000;
      const secondsPadded = `${seconds}`.padStart(2, '0');

      return `(${minutes}:${secondsPadded})`;
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';

.email-verification-form {
  width: 360px;
  max-width: 100%;

  &__error-message {
    color: $color-error;
    --u-icon-color: #{$color-error};
    margin-top: 5px;
  }

  &__text {
    color: $color-grey600;

    &--accent {
      font-weight: bold;
      color: $color-graphite;
    }
  }
}
</style>
