import { render, staticRenderFns } from "./EmailVerificationPageTemplate.vue?vue&type=template&id=282dbf9b&"
import script from "./EmailVerificationPageTemplate.vue?vue&type=script&lang=js&"
export * from "./EmailVerificationPageTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports